/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }  
.mat-mdc-dialog-container {
    border-radius: 50px !important; 
    overflow: hidden !important; 
  }
  .mat-mdc-dialog-actions {
    position: absolute;
    bottom: 0;
    width: 100%;
}
  .custom-dialog-container .mat-mdc-dialog-container {
    background-color: transparent;
    box-shadow: none; /* Optional: Remove box shadow if desired */
  }
  .custom-dialog-detail .mat-mdc-dialog-container {
    border-radius: 30px;
background: #EFEFEF;
  }
  .mat-mdc-tab-group.mat-primary .mat-ink-bar, .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #5061CE;
  }
/* img{
  width: 691px;
  height: 356px;
  border-radius: 20px;
} */